import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';

const aws_config = {
  API: {
    GraphQL: {
      endpoint: `https://api.${process.env.GATSBY_JONNI_DOMAIN}/graphql`,
      region: process.env.GATSBY_CLOUD_AWS_REGION as string,
      defaultAuthMode: 'userPool',
      graphql_headers: async () => {
        try {
          const { idToken } = (await fetchAuthSession()).tokens ?? {};
          return { Authorization: idToken };
        } catch (err) {
          console.error(err);
          return {};
        }
      },
    },
  },
  Auth: {
    Cognito: {
      identityPoolId: process.env.GATSBY_CLOUD_AWS_COGNITO_IDENTITY_POOL_ID as string,
      userPoolId: process.env.GATSBY_CLOUD_AWS_USER_POOLS_ID as string,
      userPoolClientId: process.env.GATSBY_CLOUD_AWS_USER_POOLS_WEB_CLIENT_ID as string,
    },
  },
  // aws_appsync_graphqlEndpoint: `https://api.${process.env.GATSBY_JONNI_DOMAIN}/graphql`,
  // aws_appsync_region: process.env.GATSBY_CLOUD_AWS_REGION,
  // aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  // aws_cognito_identity_pool_id: process.env.GATSBY_CLOUD_AWS_COGNITO_IDENTITY_POOL_ID,
  // aws_cognito_region: process.env.GATSBY_CLOUD_AWS_REGION,
  // aws_user_pools_id: process.env.GATSBY_CLOUD_AWS_USER_POOLS_ID,
  // aws_user_pools_web_client_id: process.env.GATSBY_CLOUD_AWS_USER_POOLS_WEB_CLIENT_ID,
  // graphql_headers: async () => {
  //   try {
  //     const { idToken } = (await fetchAuthSession()).tokens ?? {};
  //     return { Authorization: idToken };
  //   } catch (err) {
  //     console.error(err);
  //     return {};
  //     // TODO: error handling?
  //   }
  // },
};
Amplify.configure({
  ...aws_config,
  Analytics: {
    //@ts-ignore
    disabled: true,
  },
});
