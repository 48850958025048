import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import doneGreen from 'assets/doneGreen.svg';

export const WrapperSteps = styled('div')(({ theme }: { theme?: Theme }) => ({
  width: theme?.spacing(22.75),
  margin: theme?.spacing(0, 3.5),
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme?.breakpoints.down('md') as string]: {
    display: 'none',
  },
}));

export const StyledStep = styled('div')(({ nextStep }: { nextStep?: boolean }) => ({
  display: 'flex',
  fontSize: '16px',
  color: '#fff',
  opacity: nextStep ? 1 : 0.5,
  zIndex: 1,
}));

export const StyledNumberStep = styled('div')(({ theme }: { theme?: Theme }) => ({
  zIndex: 2,
  fontWeight: 'bold',
  fontSize: '16px',
  width: '30px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#4357f2',
  position: 'static',
  marginTop: theme?.spacing(-1),
}));

export const StyledStepsText = styled.div`
  min-width: 140px;
  margin-inline-start: 23px;
`;

export const StyledStepTitle = styled.div`
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: bold;
`;

export const DoneGreen = styled(doneGreen)`
  //margin: 0 -10px;
  position: relative;
`;
