import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import colors from 'src/styles/colors';
import jonniLogoWhite from 'assets/jonniLogoWhite.svg';

export const StepsMobileContainer = styled('div')(({ theme }: { theme?: Theme }) => ({
  position: 'fixed',
  width: '100%',
  height: '80px',
  zIndex: 4,
  top: '80px',
  display: 'flex',
  flexDirection: 'row',
  padding: '0 30px',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme?.breakpoints.up('sm') as string]: {
    padding: '0 70px',
  },
}));

export const StyledStep = styled('div')(({ nextStep }: { nextStep?: boolean }) => ({
  display: nextStep ? 'contents' : 'none',
  fontSize: '16px',
  color: '#fff',
  zIndex: 1,
  alignSelf: 'center',
}));

export const StyledStepsText = styled('div')(({ center }: { center?: number }) => ({
  minWidth: '140px',
  margin: center === 0 ? 'auto' : '0px',
  color: 'white',
}));

export const StyledStepTitle = styled('div')(({ center }: { center?: number }) => ({
  color: `${colors.secondaryBackground}`,
  fontSize: '18px',
  fontWeight: 'bold',
  textAlign: center === 0 ? 'center' : 'right',
}));

export const SignupTopbarContainer = styled.div`
  background: ${colors.primary};
  position: fixed;
  width: 100%;
  height: 175px;
  z-index: 4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 26px;
`;

export const Logo = styled(jonniLogoWhite)`
  height: 32px;
  width: 160px;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 auto;
  padding-right: 24px;
`;

export const Padding = styled.div`
  padding-bottom: 100px;
`;
