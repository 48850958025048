import styled from '@emotion/styled';
import {
  Button,
  Divider,
  Card,
  AppBar,
  Typography,
  IconButton,
  Grid,
  Box,
  Paper,
  CircularProgress,
} from '@mui/material';

interface styledProps {
  padding?: string;
  paddingtop?: string;
  paddingbottom?: string;
  margin?: string;
  paddingstart?: string;
  paddingend?: string;
  marginstart?: string;
  marginend?: string;
  marginbottom?: string;
  margintop?: string;
  display?: string;
  justifycontent?: string;
  alignitems?: string;
  flexdirection?: string;
  width?: string;
  height?: string;
  color?: string;
  boxsizing?: string;
  boxshadow?: string;
  visibility?: string;
  fontWeight?: string;
  borderradius?: string;
  cursor?: string;
  borderbottom?: string;
  zindex?: number;
  border?: string;
  background?: string;
  maxwidth?: string;
  minwidth?: string;
  maxheight?: string;
  minheight?: string;
  flexflow?: string;
  textDecoration?: string;
  lineheight?: string;
  fontsize?: string;
  position?: string;
}

const getStyleValue = (name: string, value?: string | number) => {
  return value ? `${name}: ${value}` : '';
};

export const createStyledComponent = (Tag: any) => {
  return styled(Tag)(
    (props: styledProps) => `
  ${getStyleValue('width', props.width)};
  ${getStyleValue('height', props.height)};
  ${getStyleValue('cursor', props.cursor)};
  ${getStyleValue('color', props.color)};
  ${getStyleValue('display', props.display)};
  ${getStyleValue('justify-content', props.justifycontent)};
  ${getStyleValue('align-items', props.alignitems)};
  ${getStyleValue('flex-direction', props.flexdirection)};
  ${getStyleValue('padding', props.padding)};
  ${getStyleValue('padding-top', props.paddingtop)};
  ${getStyleValue('padding-bottom', props.paddingbottom)};
  ${getStyleValue('margin', props.margin)};
  ${getStyleValue('margin-top', props.margintop)};
  ${getStyleValue('margin-bottom', props.marginbottom)};
  ${getStyleValue('padding-inline-start', props.paddingstart)};
  ${getStyleValue('padding-inline-end', props.paddingend)};
  ${getStyleValue('margin-inline-end', props.marginend)};
  ${getStyleValue('margin-inline-start', props.marginstart)};
  ${getStyleValue('box-sizing', props.boxsizing)};
  ${getStyleValue('box-shadow', props.boxshadow)};
  ${getStyleValue('font-weight', props.fontWeight)};
  ${getStyleValue('visibility', props.visibility)};
  ${getStyleValue('border-radius', props.borderradius)};
  ${getStyleValue('border-bottom', props.borderbottom)};
  ${getStyleValue('z-index', props.zindex)};
  ${getStyleValue('position', props.position)};
  ${getStyleValue('border', props.border)};
  ${getStyleValue('background', props.background)};
  ${getStyleValue('max-width', props.maxwidth)};
  ${getStyleValue('min-width', props.minwidth)};
  ${getStyleValue('max-height', props.maxheight)};
  ${getStyleValue('min-height', props.minheight)};
  ${getStyleValue('flex-flow', props.flexflow)};
  ${getStyleValue('text-decoration', props.textDecoration)};
  ${getStyleValue('line-height', props.lineheight)};
  ${getStyleValue('font-size', props.fontsize)};
`,
  );
};

export const StyledAppBar = createStyledComponent(AppBar);
export const StyledPaper = createStyledComponent(Paper);
export const StyledBox = createStyledComponent(Box);
export const StyledSpan = createStyledComponent('span');
export const StyledDiv = createStyledComponent('div');
export const StyledImg = createStyledComponent('img');
export const StyledGrid = createStyledComponent(Grid);
export const StyledTypography = createStyledComponent(Typography);
export const StyledCard = createStyledComponent(Card);
export const StyledIconButton = createStyledComponent(IconButton);
export const StyledButton = createStyledComponent(Button);
export const StyledDivider = createStyledComponent(Divider);
export const StyledCircularProgress = createStyledComponent(CircularProgress);
